import React, { useState } from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import About2 from "../components/about/About2";
import Counter from "../components/counter/Counter";
import Portfolio from "../components/portfolio/Portfolio";
import Team from "../components/team/Team";
import dataTeam from "../assets/fake-data/dataTeam";
import Footer from "../components/footer/Footer";
import dataPortfolio from "../assets/fake-data/data-portfolio";

import Button from "../components/button/Button";

import img from "../assets/images/new/team.webp";
import img2 from "../assets/images/new/soft.webp";
import Footer2 from "../components/footer/Footer2";
import Partner from "../components/partner/Partner";
import Team2 from "../components/team/Team2";
import VisionsMission from "./VisionsMission";

function Refund(props) {
  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Next Generation Software Solutions ",

    desc1:
      "GenSolution, terms a pioneering software firm dedicated to delivering innovative technology solutions that drive success and transform industries. ",
    desc2:
      "We have grown into a trusted partner for businesses of all sizes, offering a diverse portfolio of products and services designed to meet the unique challenges of the modern digital landscape.",
  });
  return (
    <div className="page-team wrapper">
      <div>
        <div>
          <p style={{ padding: "50px" }}>
            # Refund Policy for GenSolution LLC
            <br />
            <br />
            **Effective Date:1/1/2024
            <br />
            <br />
            At GenSolution LLC, we want you to be fully satisfied with your
            purchase. If for any reason you are not happy with your order, we
            are here to help. This Refund Policy outlines the terms and
            conditions for returning or exchanging products purchased from us.
            <br />
            <br />
            ## 1. **Eligibility for Returns and Refunds**
            <br />
            To be eligible for a return or refund, the following conditions must
            be met: -
            <br />
            **Timeframe:** You must request a return within 15 days of receiving
            your product. -
            <br />
            **Condition:** The product must be unused, in its original
            packaging, and in the same condition as when you received it. -
            <br />
            **Proof of Purchase:** A valid receipt or proof of purchase must be
            provided with your return request.
            <br />
            <br />
            ## 2. **Non-Returnable Items**
            <br />
            Certain items are not eligible for return or refund. These include:
            <br />
            - Perishable goods (e.g., food, flowers).
            <br />
            - Custom-made or personalized products. <br />
            - Digital downloads or software products. <br />
            - Gift cards. - Sale or clearance items (unless stated otherwise).
            <br />
            <br />
            ## 3. **Return Process**
            <br />
            To initiate a return, follow these steps:
            <br />
            1. **Contact Us:** Email us at service@gen-solution.com or call
            +13075004586 (USA) to request a return authorization.
            <br />
            2. **Return Authorization:** Once your return is approved, we will
            provide you with instructions for returning the product.
            <br />
            3. **Shipping:** You are responsible for the cost of shipping the
            item back to us unless the return is due to our error (e.g.,
            defective or incorrect item). We recommend using a trackable
            shipping service.
            <br />
            ## 4. **Refunds** Once we receive and inspect your returned item, we
            will notify you via email about the status of your refund. If your
            return is approved, we will process the refund using the original
            payment method. Refunds typically take [Insert Number] business days
            to appear in your account, depending on your bank or payment
            provider.
            <br />
            <br /> ## 5. **Late or Missing Refunds** If you haven’t received
            your refund within the stated timeframe, please:
            <br />
            1. Check your bank account or credit card statement.
            <br /> 2. Contact your bank or credit card provider, as processing
            times may vary.
            <br />
            <br /> 3. If you’ve done all of the above and still have not
            received your refund, please contact us at service@gen-solution.com.
            <br />
            <br /> ## 6. **Exchanges**
            <br />
            <br /> We only replace items if they are defective or damaged. If
            you need an exchange for the same item, please contact us at [Insert
            Email Address] or call +13075004586 (USA).
            <br />
            <br /> ## 7. **Damaged or Defective Items** If you receive a damaged
            or defective product, please contact us immediately with details and
            photos of the issue. We will arrange for a replacement or refund at
            no additional cost to you.
            <br />
            <br /> ## 8. **Cancellations** You can cancel your order if it has
            not yet been shipped. Please contact us as soon as possible to
            request a cancellation. If your order has already shipped, you will
            need to follow the return process outlined above.
            <br />
            <br /> ## 9. **Gifts** If the item was marked as a gift when
            purchased and shipped directly to you, you’ll receive a gift credit
            for the value of the returned item. Once the returned item is
            received and approved, a gift certificate will be mailed to you. ##
            <br />
            <br />
            10. **Contact Information** For any questions about this Refund
            Policy, please contact us at: GenSolution LLC (30 N Gould St Suite
            R, Sheridan, WY 82801, United States) Email:
            service@gen-solution.com Phone: +13075004586 (USA) --- This Refund
            Policy applies only to purchases made directly through GenSolution
            LLC. We reserve the right to modify this policy at any time, so
            please review it periodically.
          </p>
        </div>

        {/* <Team2 data={dataTeam} /> */}

        <section style={{ marginTop: "50px" }} className="create">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="create__main">
                  <div className="content">
                    <h4 className="heading">Create your systems with us</h4>
                    <p>Get udpated with news, tips & tricks</p>
                    <Button title="Join Now" link="/contact" />
                  </div>
                  <img src={img} alt="Cyfonii" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer2 />
      </div>
    </div>
  );
}

export default Refund;
