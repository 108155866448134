import React, { useState } from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Counter from "../components/counter/Counter";
import Footer from "../components/footer/Footer";
import Faqs from "../components/faqs/Faqs";
import dataFaqs from "../assets/fake-data/data-faq";

import img1 from "../assets/images/new/about1.webp";
import img2 from "../assets/images/new/about2.png";
import img3 from "../assets/images/new/about3.webp";
import img4 from "../assets/images/new/about4.png";
import img5 from "../assets/images/new/about5.webp";

function VisionsMission(props) {
  const [dataImg] = useState([
    {
      id: 1,
      img: img1,
      class: "img1",
    },
    {
      id: 2,
      img: img2,
      class: "img2",
    },
    {
      id: 3,
      img: img3,
      class: "img3",
    },
    {
      id: 4,
      img: img4,
      class: "img4",
    },
    {
      id: 5,
      img: img5,
      class: "img5",
    },
  ]);
  return (
    <div className="page-visions wrapper">
      <PageTitle title="Next Generation Software Solutions" />
      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <p style={{ fontSize: "20px" }} className="mb-29">
                  GenSolution, a pioneering software firm dedicated to
                  delivering innovative technology solutions that drive success
                  and transform industries.
                  <br /> We have grown into a trusted partner for businesses of
                  all sizes, offering a diverse portfolio of products and
                  services designed to meet the unique challenges of the modern
                  digital landscape.
                </p>
                <h6 className="sub-heading">
                  <span>Visions & Mission</span>
                </h6>
                <h3 className="heading wow" data-splitting>
                  Our Vision
                </h3>
                <p style={{ fontSize: "20px" }} className="mb-17">
                  Innovation: We are committed to pushing the boundaries of
                  technology to develop innovative solutions that solve
                  real-world problems. <br /> Integrity: We conduct our business
                  with honesty, transparency, and respect, building lasting
                  relationships based on trust. <br /> Excellence: We strive for
                  excellence in everything we do, from the quality of our
                  products to the services we provide. <br /> Customer Focus:
                  Our clients are at the heart of our business. We listen to
                  their needs and work tirelessly to exceed their expectations.
                  <br />
                  Collaboration: We believe in the power of teamwork and foster
                  a collaborative environment where ideas can flourish.
                </p>
              </div>
              <div className="block-text">
                <h3 className="heading wow" data-splitting>
                  Our Mission
                </h3>
                <p style={{ fontSize: "20px" }}>
                  Our mission is to empower businesses and individuals through
                  innovative and reliable software solutions. We strive to
                  create cutting-edge technology that enhances productivity,
                  fosters growth, and transforms industries. Our commitment to
                  excellence, customer satisfaction, and continuous improvement
                  drives us to deliver high-quality, user-friendly products and
                  services that meet the evolving needs of our clients.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="about__right">
                <div className="images">
                  {dataImg.map((idx) => (
                    <img
                      key={idx.id}
                      className={idx.class}
                      src={idx.img}
                      alt="cyfonii"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default VisionsMission;
