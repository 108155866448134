


import About from "./About";
import AdvisorsBackers from "./AdvisorsBackers";
import Blog from "./Blog";

import BlogDetails from "./BlogDetails";

import Contact from "./Contact";
import Finance from "./Blog/Finance";
import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Nfts from "./Nfts";
import ParticipantAssets from "./ParticipantAssets";
import Partners from "./Partners";
import RoadMap from "./RoadMap";
import Team from "./Team";
import VisionsMission from "./VisionsMission";
import Ai from "./Blog/Ai";
import Security from "./Blog/Security";
import Services from "./Services";
import Project from "./Project";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Refund from "./Refund";






const routes = [
  { path: '/', component: <Home02 /> },
  { path: '/home-v2', component: <Home01 /> },
  { path: '/home-v3', component: <Home03 /> },

  { path: '/nfts', component: <Nfts /> },
  { path: '/blog', component: <Blog /> },
  { path: '/blog-details', component: <BlogDetails /> },
  { path: 'blog/fintech', component: <Finance /> },
  { path: 'blog/ai-startup', component: <Ai /> },
  { path: 'blog/cyber-security', component: <Security /> },
  { path: '/visions-mission', component: <VisionsMission /> },
  { path: '/help-center', component: <HelpCenter /> },
  { path: '/participants-assets', component: <ParticipantAssets /> },
  { path: '/advisors-backers', component: <AdvisorsBackers /> },
  { path: '/partners', component: <Partners /> },
  { path: '/services', component: <Services /> },
  { path: '/projects', component: <Project /> },
  { path: '/about', component: <About /> },
  { path: '/road-map', component: <RoadMap /> },
  { path: '/team', component: <Team /> },
  { path: '/contact', component: <Contact /> },
  { path: '/terms-condition', component: <Terms /> },
  { path: '/privacy-policy', component: <Privacy /> },
  { path: '/refund-policy', component: <Refund /> },



]

export default routes;