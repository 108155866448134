import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import data from "../../assets/fake-data/dataPartner";
import { Navigation, Pagination } from "swiper";

function Partner(props) {
  return (
    <div
      style={{ marginTop: "30px" }}
      className="brands block-text center"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <h6 className="sub-heading">
        <span>We Are Working with Top Brands</span>
      </h6>

      <Swiper
        className=""
        spaceBetween={30}
        breakpoints={{
          0: {
            slidesPerView: 4,
          },
          500: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 4,
          },
          991: {
            slidesPerView: 6,
          },
        }}
        loop={true}
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
      >
        {data.map((idx) => (
          <SwiperSlide key={idx.id}>
            <Link to="#">
              <img
                title={idx?.title}
                style={{ width: "70px", height: "70px", marginBottom: "50px" }}
                src={idx.img}
                alt="GenSolution"
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Partner;
