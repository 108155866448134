
import icon1 from '../images/icon/quote-2.png'
import avt1 from '../images/new/kayan.png'
import avt2 from '../images/new/fuzael.jpg'
import avt3 from '../images/new/safeen.jpg'


const dataTestimonials2 = [

    {
        id: 1,
        icon: icon1,
        text: '“ GenSolution delivered a fantastic software solution, exceeding our expectations. Their team was professional, responsive, and highly skilled. We highly recommend them for top-notch software development.“',
        avt: avt1,
        name: 'Mr Kayan',
        position: 'CBO, Spider Digital'
    },
    {
        id: 2,
        icon: icon1,
        text: `“ GenSolution's expertise and dedication resulted in a user- friendly and reliable application.Excellent communication and timely delivery made the experience seamless.Highly recommended! “`,
        avt: avt2,
        name: 'Fuzael Ibn Elius',
        position: 'CEO & Founder at StudyHive'
    },
    {
        id: 3,
        icon: icon1,
        text: 'GenSolution provided exceptional service and technical proficiency. They delivered a high-quality product on time, with great support throughout. We highly recommend them for any software development needs. “',
        avt: avt3,
        name: 'Safeen Shahadat',
        position: 'Digital marketing lead, Papyrus'
    },

]

export default dataTestimonials2;