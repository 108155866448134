import React, { useState } from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import About2 from "../components/about/About2";
import Counter from "../components/counter/Counter";
import Portfolio from "../components/portfolio/Portfolio";
import Team from "../components/team/Team";
import dataTeam from "../assets/fake-data/dataTeam";
import Footer from "../components/footer/Footer";
import dataPortfolio from "../assets/fake-data/data-portfolio";

import Button from "../components/button/Button";

import img from "../assets/images/new/team.webp";
import img2 from "../assets/images/new/soft.webp";
import Footer2 from "../components/footer/Footer2";
import Partner from "../components/partner/Partner";
import Team2 from "../components/team/Team2";
import VisionsMission from "./VisionsMission";

function Privacy(props) {
  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Next Generation Software Solutions ",

    desc1:
      "GenSolution, terms a pioneering software firm dedicated to delivering innovative technology solutions that drive success and transform industries. ",
    desc2:
      "We have grown into a trusted partner for businesses of all sizes, offering a diverse portfolio of products and services designed to meet the unique challenges of the modern digital landscape.",
  });
  return (
    <div className="page-team wrapper">
      <div>
        <div>
          <p style={{ padding: "50px" }}>
            Privacy Policy for GenSolution LLC <br />
            <br />
            **Effective Date:1/1/2024
            <br />
            <br />
            At GenSolution LLC, we are committed to protecting your privacy.
            This Privacy Policy outlines how we collect, use, disclose, and
            safeguard your information when you visit our website
            https://www.gen-solution.com/ and purchase our products or services.
            By accessing or using the Site, you agree to the terms of this
            Privacy Policy.
            <br />
            <br /> **1. Information We Collect** We collect information from you
            in various ways, including: -<br />
            <br /> **Personal Information:** When you make a purchase or create
            an account, we may collect personal details such as your name, email
            address, phone number, shipping and billing address, and payment
            information. -<br />
            <br /> **Non-Personal Information:** We may collect non-identifiable
            information such as IP addresses, browser types, operating systems,
            referring URLs, and browsing behavior on our website. -<br />
            <br /> **Cookies and Tracking Technologies:** We use cookies,
            pixels, and other tracking technologies to enhance your experience
            on our site and gather data on user behavior.
            <br />
            <br /> **2. How We Use Your Information** We may use your
            information for purposes including:
            <br />
            <br /> - Processing transactions and fulfilling orders. -
            Communicating with you regarding your orders or inquiries.
            <br />
            <br /> - Sending promotional materials, newsletters, or updates
            (with your consent).
            <br />
            <br /> - Personalizing your experience on our website. - Analyzing
            site performance and improving our products and services. -
            Complying with legal obligations.
            <br />
            <br /> **3. Sharing Your Information** <br />
            <br />
            We do not sell or rent your personal information to third parties.
            However, we may share your information in the following cases: -
            <br />
            <br /> **Service Providers:** We may share your information with
            trusted service providers who help us operate the site, process
            payments, or ship orders. -<br />
            <br /> **Legal Requirements:** We may disclose your information if
            required by law, court order, or in response to government requests.
            - <br />
            <br />
            **Business Transfers:** In the event of a merger, acquisition, or
            sale of assets, your information may be transferred as part of that
            transaction.
            <br />
            <br /> **4. Data Security** We implement reasonable security
            measures to protect your information from unauthorized access,
            disclosure, or misuse. However, no system is 100% secure, and we
            cannot guarantee absolute security of your information.
            <br />
            <br /> **5. Your Choices** You have the right to: - <br />
            <br />
            **Access or Correct Your Information:** You can view or update your
            personal information by logging into your account. -<br />
            <br />
            **Opt-Out of Marketing Communications:** You can unsubscribe from
            promotional emails by following the instructions in the email. -
            <br />
            <br />
            **Disable Cookies:** You can set your browser to refuse cookies, but
            this may limit your ability to use certain features of the Site.
            <br />
            <br /> **6. Children’s Privacy** Our site is not intended for use by
            individuals under the age of 13. We do not knowingly collect
            personal information from children under 13. If we discover we have
            inadvertently gathered such information, we will promptly delete it.
            <br />
            <br /> **7. Third-Party Links** Our site may contain links to
            third-party websites. We are not responsible for the privacy
            practices of these websites, and we encourage you to read their
            privacy policies. <br />
            <br />
            **8. Changes to This Privacy Policy** We may update this Privacy
            Policy from time to time. Any changes will be posted on this page,
            and the revised policy will indicate the effective date. Your
            continued use of the Site after changes are made will constitute
            acceptance of those changes.
            <br />
            <br /> **9. Contact Us** If you have any questions or concerns about
            this Privacy Policy, please contact us at: GenSolution LLC (30 N
            Gould St Suite R, Sheridan, WY 82801, United States) Email:
            service@gen-solution.com Phone: +13075004586 (USA) --- This Privacy
            Policy applies solely to information collected through the Site and
            does not apply to any other business activities of GenSolution LLC.
          </p>
        </div>

        {/* <Team2 data={dataTeam} /> */}

        <section style={{ marginTop: "50px" }} className="create">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="create__main">
                  <div className="content">
                    <h4 className="heading">Create your systems with us</h4>
                    <p>Get udpated with news, tips & tricks</p>
                    <Button title="Join Now" link="/contact" />
                  </div>
                  <img src={img} alt="Cyfonii" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer2 />
      </div>
    </div>
  );
}

export default Privacy;
