
import img1 from '../images/new/ntmc4.png'
import img2 from '../images/new/lebupay.png'
import img3 from '../images/new/realestate1.png'
import img4 from '../images/new/xm.png'
import img5 from '../images/new/msuruz.png'
import img6 from '../images/new/cipher.png'
import img7 from '../images/new/gorun1.png'
import img8 from '../images/new/lyxa1.jpg'
import img88 from '../images/new/carebox.webp'
import img99 from '../images/new/caredrop.webp'
import img111 from '../images/new/cobalt2.jpg'
import img112 from '../images/new/ambel.jpeg'
import img114 from '../images/new/spider-digital-red.png'
import img115 from '../images/new/papyrus.png'
import img116 from '../images/new/olympic.png'
import img117 from '../images/new/Clavis.png'
import img118 from '../images/new/study.png'
import img119 from '../images/new/study_gram.png'
import img122 from '../images/new/vumi.jpg'
import img133 from '../images/new/goti.png'


const dataItem = [

    // {
    //     id: 1,
    //     img: img1,
    //     title: 'NTMC',
    //     des: 'Sasha Dashboard, Bangladesh Govt. Project',
    //     link: 'https://ntmc.gov.bd/'
    // },
    {
        id: 1111,
        img: img122,
        title: 'Ministry of Land',
        des: 'Bangladesh Govt. Project',
        link: 'https://eporcha.gov.bd/'
    },
    {
        id: 1,
        img: img114,
        title: 'Spider Digital',
        des: ' Cyber Security, Defence & Intelligence Solutions provider',
        link: 'https://spiderdigital.io/'
    },
    // {
    //     id: 2,
    //     img: img2,
    //     title: 'LebuPay',
    //     des: ' Fintech App For Remittance',
    //     link: 'http://3.111.147.160/'
    // },
    // {
    //     id: 2,
    //     img: img116,
    //     title: 'Olympic Industries Ltd',
    //     des: 'Pioneer in FMCG in Bangladesh',
    //     link: 'http://olympicbd.com/'
    // },
    {
        id: 2,
        img: img115,
        title: 'Papyrus Communications',
        des: 'Digital Marketing Solution',
        link: 'https://facebook.com/papyrusbd'
    },
    // {
    //     id: 2,
    //     img: img117,
    //     title: 'Clavis Fintech',
    //     des: 'Fintech in Singapore',
    //     link: 'https://www.clavisfintech.com/'
    // },


    {
        id: 8,
        img: img8,
        title: 'Lyxapp',
        des: ' Multi-Vendor Mobile App for Dubai & Lebanon',
        link: ''
    },
    {
        id: 888,
        img: img133,
        title: 'Goti Rentals',
        des: 'Bangladeshi Rental Ride-sharing  ',
        link: 'http://gotirentals.com/'
    },


    {
        id: 6,
        img: img6,
        title: 'Cipher Schools',
        des: 'Indian Ed-tech',
        link: 'https://www.cipherschools.com/'
    },
    {
        id: 7,
        img: img7,
        title: 'Gorun Mobile App',
        des: ' Delivery Mobile App',
        link: 'https://play.google.com/store/apps/details?id=com.gorun&hl=en'
    },
    {
        id: 722,
        img: img118,
        title: 'StudyHive Network',
        des: ' Study Abroad Portal',
        link: 'https://www.studyhivenetwork.com/'
    },
    {
        id: 5,
        img: img119,
        title: 'Study Gram',
        des: 'Student Consultancy',
        link: 'https://www.studygrambd.com/'
    }, {
        id: 4,
        img: img4,
        title: 'Exam Pools',
        des: 'Internation School curriculums Ed-tech',
        link: 'https://www.exampools.com/'
    },
    {
        id: 88,
        img: img88,
        title: 'CareBox',
        des: 'Ecommerce App',
        link: 'https://play.google.com/store/apps/details?id=care_box.carebox.com'
    },
    {
        id: 3,
        img: img3,
        title: 'Shams Al Majara',
        des: ' Real Estate in Dubai',
        link: 'https://shamsalmajara-re.com/'
    },
    {
        id: 99,
        img: img99,
        title: 'CareDrop',
        des: 'Health-care App',
        link: 'https://play.google.com/store/apps/details?id=com.caredrop'
    },
    {
        id: 111,
        img: img111,
        title: 'Cobalt Speech',
        des: 'Ai & Machine Learning Solutions Provider in the USA',
        link: 'https://www.cobaltspeech.com/'
    },
    {
        id: 111,
        img: img112,
        title: 'Ambel',
        des: 'Video Appointments Service Provider in Canada',
        link: 'https://ambel.ca/'
    },


]

export default dataItem;