import React, { useState } from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import About2 from "../components/about/About2";
import Counter from "../components/counter/Counter";
import Portfolio from "../components/portfolio/Portfolio";
import Team from "../components/team/Team";
import dataTeam from "../assets/fake-data/dataTeam";
import Footer from "../components/footer/Footer";
import dataPortfolio from "../assets/fake-data/data-portfolio";

import Button from "../components/button/Button";

import img from "../assets/images/new/team.webp";
import img2 from "../assets/images/new/soft.webp";
import Footer2 from "../components/footer/Footer2";
import Partner from "../components/partner/Partner";
import Team2 from "../components/team/Team2";
import VisionsMission from "./VisionsMission";

function Terms(props) {
  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Next Generation Software Solutions ",

    desc1:
      "GenSolution, terms a pioneering software firm dedicated to delivering innovative technology solutions that drive success and transform industries. ",
    desc2:
      "We have grown into a trusted partner for businesses of all sizes, offering a diverse portfolio of products and services designed to meet the unique challenges of the modern digital landscape.",
  });
  return (
    <div className="page-team wrapper">
      <div>
        <div>
          <p style={{ padding: "50px" }}>
            # Terms and Conditions for GenSolution LLC <br />
            <br />
            **Effective Date:1/1/2024
            <br />
            <br /> Welcome to GenSolution LLC . These Terms and Conditions
            ("Terms") govern your use of our website, products, and services
            (collectively, the “Services”). By accessing or using our Services,
            you agree to be bound by these Terms. If you do not agree with any
            part of these Terms, you should not use our Services.
            <br />
            <br /> ## 1. **Acceptance of Terms** By accessing or using any part
            of our Services, you accept and agree to be bound by these Terms. If
            you do not agree, please refrain from using the Services.
            <br />
            <br /> ## 2. **Eligibility** You must be at least 18 years of age or
            the age of majority in your jurisdiction to use our Services. By
            using our Services, you represent that you meet this age
            requirement.
            <br />
            <br /> ## 3. **Use of Services** - **Account Creation:** To access
            certain features, you may be required to create an account. You
            agree to provide accurate, complete, and up-to-date information and
            to maintain the confidentiality of your login credentials. -
            **Prohibited Activities:** You agree not to use our Services for any
            unlawful purpose or to violate any applicable local, state,
            national, or international law. Additionally, you agree not to
            engage in activities that could damage, disable, or impair the
            Services or interfere with others’ use of the Services.
            <br />
            <br /> ## 4. **Orders and Payments** - **Order Acceptance:** All
            orders placed through our website are subject to acceptance and
            availability. We reserve the right to cancel or refuse any order at
            our discretion. - **Pricing and Payment:** All prices listed on our
            website are in USD and exclude taxes and shipping fees unless
            otherwise stated. Payment is required at the time of order, and you
            agree to provide valid payment information. - **Shipping:** We will
            ship products to the address provided at the time of order. Shipping
            times and costs may vary depending on the destination.
            <br />
            <br /> ## 5. **Returns and Refunds** We offer returns and refunds in
            accordance with our **Return Policy**, which you can find
            <a
              style={{ margin: "5px", textDecoration: "underline" }}
              href="/refund-policy"
            >
              here
            </a>
            . Please review it carefully before making a purchase.
            <br />
            <br />
            ## 6. **Intellectual Property** All content, including text, images,
            logos, trademarks, and other materials found on our website, are the
            intellectual property of GenSolution LLC or its licensors and are
            protected by copyright, trademark, and other intellectual property
            laws. You may not use, copy, reproduce, or distribute any content
            from our website without our express written permission.
            <br />
            <br /> ## 7. **User-Generated Content** If you submit or post any
            content (e.g., reviews, comments, or photos) to our website or
            social media platforms, you grant us a non-exclusive, royalty-free,
            perpetual, and irrevocable license to use, modify, reproduce, and
            distribute the content in any form or medium.
            <br />
            <br /> ## 8. **Third-Party Links** Our website may contain links to
            third-party websites. We do not endorse or control these websites
            and are not responsible for their content, products, services, or
            privacy practices. You access third-party sites at your own risk. ##
            <br />
            <br />
            9. **Disclaimer of Warranties** Our Services are provided on an “as
            is” and “as available” basis without any warranties of any kind,
            express or implied. We do not guarantee that the Services will be
            uninterrupted, error-free, or secure. We disclaim any and all
            warranties, including but not limited to warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement.
            <br />
            <br /> ## 10. **Limitation of Liability** To the fullest extent
            permitted by law, GenSolution LLC will not be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            including lost profits, arising out of your use or inability to use
            the Services, even if we have been advised of the possibility of
            such damages. Our total liability to you for any claim arising out
            of or relating to these Terms or the Services is limited to the
            amount you paid us for the product or service in question.
            <br />
            <br /> ## 11. **Indemnification** You agree to indemnify, defend,
            and hold harmless GenSolution LLC, its affiliates, directors,
            employees, and agents from any claims, liabilities, damages, losses,
            or expenses, including legal fees, arising out of or related to your
            use of the Services, your violation of these Terms, or your
            infringement of any intellectual property or other rights of any
            third party.
            <br />
            <br /> ## 12. **Termination** We reserve the right to terminate or
            suspend your access to the Services at our sole discretion, without
            notice, if we believe you have violated these Terms or engaged in
            any unlawful or harmful behavior.
            <br />
            <br /> ## 13. **Governing Law** These Terms are governed by and
            construed in accordance with the laws of the United States and the
            state of Wyoming, without regard to conflict of law principles. Any
            disputes arising from these Terms or the use of the Services will be
            resolved exclusively in the state or federal courts located in
            Wyoming.
            <br />
            <br /> ## 14. **Changes to These Terms** We may update or modify
            these Terms at any time, and any changes will be effective
            immediately upon posting on our website. Your continued use of the
            Services after changes are posted constitutes your acceptance of the
            revised Terms.
            <br />
            <br /> ## 15. **Contact Us** If you have any questions or concerns
            about these Terms, please contact us at: GenSolution LLC (30 N Gould
            St Suite R, Sheridan, WY 82801, United States) Email:
            (service@gen-solution.com) Phone: (+13075004586 (USA)) --- These
            Terms and Conditions represent the entire agreement between you and
            GenSolution LLC regarding your use of the Services and supersede any
            prior agreements or understandings.
          </p>
        </div>

        {/* <Team2 data={dataTeam} /> */}

        <section style={{ marginTop: "50px" }} className="create">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="create__main">
                  <div className="content">
                    <h4 className="heading">Create your systems with us</h4>
                    <p>Get udpated with news, tips & tricks</p>
                    <Button title="Join Now" link="/contact" />
                  </div>
                  <img src={img} alt="Cyfonii" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer2 />
      </div>
    </div>
  );
}

export default Terms;
